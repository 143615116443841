//
//  Copyright  2021 주재넷(주)  all rights reserved.
//  Programmer : 박 종 호(Jong Ho Park),
//               이 승 준(Seung Jun Lee),
//               김 도 훈(Do Hoon Kim),
//               김 은 석(Eun Seok Kim),
//               윤 태 웅(Tae Woong Yun),
//               정 준 용(Jun Yong Jeong),
//               정 재 우(Jae Woo Jeong)
//  Description : BBEEGO 트레이너 매칭 시스템
//
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
// import Dots from "../component/dots/Dots";
import { useRef } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import colors from "../utils/colors";
import { routes } from "./routes";
//bbeego
const Container = styled.div`
  margin: 0 0 0 0;
  background-color: white;
  width: inherit;
  color: gray;
  overflow-y: hidden;
  :-webkit-scrollbar {
    display: none;
  }
  .section {
    position: relative;
  }
  .section:before {
    background-color: rgba(0 0 0 0.7);
  }
`;

const AboutTitle = styled.div`
  margin: 10px auto;
  font-size: 100px;
  font-weight: 550;
  padding: 70px 10px 10px 10px;
  @media (max-width: 820px) {
    font-size: 50px;
    text-align: center;
  }
`;

const AboutBtn = styled.button`
  font-family: inherit; //글꼴: 상속
  position: absolute; //페이지 레이아웃에 공간도 배정하지 않는다,top, right, bottom, left 값이 지정.
  right: 5%; //우측에서 거리
  bottom: 7%; //아래부터 거리
  white-space: nowrap;
  /* width: 13%; //버튼 크기 */
  width: 225px;
  padding: 15px; // 요소의 네 방향 안쪽 여백 영역을 설정합니다
  background-color: ${colors.bbeego};
  color: white;
  border: ${colors.bbeego}; //테두리
  border-radius: 30px; //테두리 둥글게 만들기
  font-size: 23px;
  font-weight: bold; //글자 굵기
  :hover {
    border: 2px outset #ccc;
  } //마우스 올렸을 때 반응하게 하는 요소.
  :active {
    border: 2px inset #ccc;
  } //마우스를 사용하는 경우, "활성"이란 보통 마우스 버튼을 누르는 순간부터 떼는 시점까지를 의미합니다.
  @media (max-width: 820px) {
    right: 10%;
    bottom: 10%;
    width: 80%;
  } //버튼 적용은820px부터
`;

const Discription = styled.div`
  position: relative;
  top: 33%;
  margin-left: 30px;
  color: white;
  font-size: 30px;
  text-align: left;
  @media (max-width: 820px) {
    margin-left: 0px;
  }
  h2 {
    font-size: 45px;
    padding: 10px;
    @media (max-width: 820px) {
      font-size: 25px;
      padding: 10px;
      text-align: center;
    }
  }
  p {
    font-size: 20px;
    @media (max-width: 820px) {
      font-size: 20px;
      text-align: center;
    }
  }
`;

const SectionWrap = styled.div`
  background: ${(props) => props.background};
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: 100vh;
`;

const DIVIDER_HEIGHT = 0;
const headerHeight = 0;

const About = () => {
  const [scrollIndex, setScrollIndex] = useState(1);
  const conRef = useRef();
  const sectionRef = useRef([]);
  const scrollMaxIndex = 5;
  let touchStartPoint = 0;
  let scrollable = true;
  let scrollIndexDrag = 0;
  let pageHeight = 0;

  const onSectionClick = (idx) => {
    if (!scrollable) {
      return;
    }
    scrollable = false;
    setTimeout(() => {
      scrollable = true;
    }, 700 * Math.abs(idx - scrollIndexDrag));
    setTimeout(() => {
      pageHeight =
        document.getElementsByClassName("section").length > 0
          ? document.getElementsByClassName("section")[0].clientHeight
          : 0;
      window.scrollTo({
        top: pageHeight * idx + DIVIDER_HEIGHT + headerHeight,
        left: 0,
        behavior: "smooth",
      });
      setScrollIndex(idx + 1);
      scrollIndexDrag = idx;
    }, 1);
  };

  const DotButton = styled.button`
    background-color: transparent;
    border: transparent;
  `;
  const Dot = ({ num, scrollIndex }) => {
    return (
      <div
        style={{
          width: 15,
          height: 15,
          border: "1px solid #ccc",
          borderRadius: 999,
          backgroundColor: scrollIndex === num ? "#888888" : "transparent",
          transitionDuration: 1000,
          transition: "background-color 0.8 s",
        }}
      ></div>
    );
  };

  const Dots = ({ scrollIndex }) => {
    return (
      <div style={{ position: "fixed", top: "45%", right: "5%" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            width: 20,
            height: 100,
          }}
        >
          <DotButton onClick={() => onSectionClick(0)} click="1">
            <Dot num={1} scrollIndex={scrollIndex}></Dot>
          </DotButton>
          <DotButton onClick={() => onSectionClick(1)}>
            <Dot num={2} scrollIndex={scrollIndex}></Dot>
          </DotButton>
          <DotButton onClick={() => onSectionClick(2)}>
            <Dot num={3} scrollIndex={scrollIndex}></Dot>
          </DotButton>
          <DotButton onClick={() => onSectionClick(3)}>
            <Dot num={4} scrollIndex={scrollIndex}></Dot>
          </DotButton>
        </div>
      </div>
    );
  };
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    const wheelHandler = (e) => {
      try {
        e.preventDefault();
      } catch (ex) {}
      if (!scrollable) {
        //e.preventDefault();
        return;
      } else {
        scrollable = false;
        setTimeout(() => {
          scrollable = true;
        }, 500);
        //console.log("e :", e);
        pageHeight =
          document.getElementsByClassName("section").length > 0
            ? document.getElementsByClassName("section")[0].clientHeight
            : 0; //window.innerHeight;

        const { deltaY } = e;
        const scrollTop = document.getElementsByTagName("html")[0].scrollTop;
        let index = Math.round(scrollTop / pageHeight);
        if (deltaY > 0) {
          // scroll down
          index++;
          if (index >= scrollMaxIndex - 1) {
            index = scrollMaxIndex - 1;
          }
          window.scrollTo({
            top: pageHeight * index + DIVIDER_HEIGHT + headerHeight,
            left: 0,
            behavior: "smooth",
          });
          //setScrollIndex(index + 1);
        } else if (deltaY < 0) {
          index--;
          if (index <= 0) {
            index = 0;
          }
          window.scrollTo({
            top: pageHeight * index + DIVIDER_HEIGHT + headerHeight,
            left: 0,
            behavior: "smooth",
          });
        }
      }
    };
    const touchStart = (e) => {
      touchStartPoint = e.touches[0].clientY;
    };
    const touchEnd = (e) => {
      const nowY = e.changedTouches[0].clientY;

      if (nowY > touchStartPoint) {
        wheelHandler(new WheelEvent("tmpEvent", { deltaY: -100 }));
      } else if (nowY < touchStartPoint) {
        wheelHandler(new WheelEvent("tmpEvent", { deltaY: 100 }));
      }
    };
    const afterScroll = (e) => {
      try {
        const nowY = document.getElementsByTagName("html")[0].scrollTop;
        if (pageHeight > 0) {
          const index = Math.round(nowY / pageHeight);
          setScrollIndex(index + 1);
        }
      } catch (ex) {}
    };
    window.addEventListener("wheel", wheelHandler, { passive: false });
    window.addEventListener("touchstart", touchStart, { passive: false });
    window.addEventListener("touchend", touchEnd, { passive: false });
    window.addEventListener("scroll", afterScroll, { passive: false });
    return () => {
      window.removeEventListener("wheel", wheelHandler, { passive: false });
      window.removeEventListener("touchstart", touchStart, { passive: false });
      window.removeEventListener("touchend", touchEnd, { passive: false });
      window.removeEventListener("scroll", afterScroll, { passive: false });
      document.getElementsByTagName("body")[0].style.overflow = "unset";
    };
  }, []);

  return (
    <Container ref={conRef} id="aboutMainContainer">
      <SectionWrap
        background="url(https://cdn.pixabay.com/photo/2017/05/25/15/08/jogging-2343558_960_720.jpg)"
        className="section"
        ref={(el) => (sectionRef.current[0] = el)}
      >
        <Discription>
          <AboutTitle>Purpose</AboutTitle>
          <h2>
            얼굴 빼고 살 빼고 싶은 사람들에게 <br />
            도움을 주고자 하는 마음으로 <br />
            BBEEGO라는 이름이 탄생되었습니다.
          </h2>
        </Discription>
      </SectionWrap>
      <SectionWrap
        background="url(https://cdn.pixabay.com/photo/2014/11/17/13/17/crossfit-534615_1280.jpg)"
        className="section"
        ref={(el) => (sectionRef.current[1] = el)}
      >
        <Discription>
          <h2>
            원격으로 자신에게 맞는 <br />
            PT룸을 선택하고 <br />
            트레이너와 상담해보세요!
          </h2>
          <p>
            &nbsp;트레이너는 직접 PT룸을 개설하여 회원을 모집할 수 있습니다.
          </p>
        </Discription>
        <AboutBtn classNa="btn btn-warning rounded-pill">
          <Link to={routes.pTRooms} style={{ color: "white" }}>
            PT룸으로 이동&ensp;
            <FontAwesomeIcon size="lg" icon={faAngleRight} />
          </Link>
        </AboutBtn>
      </SectionWrap>
      {/* <Divider /> */}
      <SectionWrap
        background="url(https://cdn.pixabay.com/photo/2017/01/11/18/35/blur-1972569_1280.jpg)"
        className="section"
        // ref={challengeRef}
        ref={(el) => (sectionRef.current[2] = el)}
      >
        <Discription>
          <h2>
            챌린지를 생성하여 <br />
            개인 트레이닝을 받아보세요!
          </h2>
          <p>&nbsp;보상을 위해 트레이너도 당신을 더 열심히 케어할 것입니다.</p>
        </Discription>
        <AboutBtn>
          <Link to={routes.multiChallengeList} style={{ color: "white" }}>
            챌린지 생성하기&ensp;
            <FontAwesomeIcon size="lg" icon={faAngleRight} />
          </Link>
        </AboutBtn>
      </SectionWrap>
      {/* <Divider /> */}
      <SectionWrap
        background="url(https://cdn.pixabay.com/photo/2017/09/08/10/27/slimming-2728331_1280.jpg)"
        className="section"
        // ref={scanRef}
        ref={(el) => (sectionRef.current[3] = el)}
      >
        <Discription>
          <h2>
            사진 한 장으로 <br />
            BodyShape을 확인해보세요!
          </h2>
          <p>
            &nbsp;당신의 체형을 스캔하여 트레이너에게 객관적인 자료를 제공할 수
            있습니다. <br />
            &nbsp;(챌린지 준우승자 이상)
          </p>
        </Discription>
        {/* <AboutBtn>
          <Link to={routes.scanning} style={{ color: "white" }}>
            3D 스캔하기&ensp;
            <FontAwesomeIcon size="lg" icon={faAngleRight} />
          </Link>
        </AboutBtn> */}
      </SectionWrap>
      <div>
        <Dots scrollIndex={scrollIndex} />
      </div>
    </Container>
  );
};
export default About;
